import * as React from 'react'
import { CardModule, ICardModuleProps } from '../CardModule/CardModule';
import { ScheduledActivity } from '../../../components/activity_generator/ScheduledActivity';
import { BoxedModule } from '../../BoxedModule';
import { deleteData } from '../../../utils/Firebase';
import { data, getDataPath, getModulesPath } from '../../../utils/constants';
import ScheduledActivityViewer from './ScheduledActivityViewer';

export interface IScheduledActivityCardModuleProps {
    scheduledActivity: ScheduledActivity;
    onUpdateScheduledActivity: (updatedScheduledActivity: ScheduledActivityCardModule) => void;
}

export class ScheduledActivityCardModule extends CardModule implements IScheduledActivityCardModuleProps {
    scheduledActivity: ScheduledActivity;
    onUpdateScheduledActivity: (updatedScheduledActivity: ScheduledActivityCardModule) => void;

    constructor(
        baseProps,
        boardModuleProps: ICardModuleProps,
        scheduledActivityCardProps: IScheduledActivityCardModuleProps
    ) {
        super({ ...baseProps }, boardModuleProps);
        Object.assign(this, scheduledActivityCardProps)
    }

    getScheduledActivityViewerDialog = () => <ScheduledActivityViewer
        hideHeader
        scheduledActivity={this.scheduledActivity}
        onDeleteScheduledActivity={() => {
            if (this.parentModule) {
                this.parentModule.removeModule(this.id)
            }
        }}
        onUpdateScheduledActivity={() => {
            this.onUpdateScheduledActivity(this);
            this.parentModule && this.parentModule.removeModule(this.id);
            this.closeModal();
        }}
    />

    // försök flytta den här till TodoBoard så att motsvarande kort i dess board tas bort när den här köas upp till UpcomingBoardModule
    showCardContainer = () => {
        this.toggleModal(<BoxedModule
            moduleName={this.name}
            moduleId={this.id}
            dragHandle={this.dragHandle}
            // actionMenu={[<div>hej</div>]}
            content={
                this.getScheduledActivityViewerDialog()
            }
            onMinimize={() => { }/* (minimized: boolean) => onMinimizeModule(minimized, baseModule.sitemapData) */}
            onDelete={() => {
                console.log(this.parentModule);
                if (this.parentModule) {
                    this.parentModule.removeModule(this.id);
                }
            }}
            onHeaderNameUpdate={newName => {

            }}
        />)
    }

    toFirebaseObject() {
        return { id: this.id, name: this.name, type: this.type, scheduledActivity: this.scheduledActivity.id, layout: this.layout?.getILayout() }
    }

    deleteFromFirebase() {
        deleteData(getDataPath(this.user, data.SCHEDULED_ACTIVITY, this.id))
        deleteData(getModulesPath(this.user, this.id))
    }

}