import * as React from 'react';
import { BaseModule, IBaseModule } from "../BaseModule";
import { Activity } from '../../../data/activity/Activity';
import { getDuration, getNow } from '../../../utils/TimeUtils';
import { Button } from '../../../components/button/Button';
import { ScheduledActivity } from '../../../components/activity_generator/ScheduledActivity';
import { IRow, ICell, Table } from '../../../components/table/Table';
import { Row } from '../../../components/layout/row/Row';
import { PlusIcon } from '../../../icons/PlusIcon';
import { QuickActivityCreator } from '../../../components/quick_activity_creator/QuickActivityCreator';
import { ActivityStatus, breakpointTypes, getModulesPath, GridHeightsLG, GridHeightsSM, GridWidthsLG, GridWidthsSM, ILayouts } from '../../../utils/constants';
import { TData } from '../../../app/interface';
import { writeData } from '../../../utils/Firebase';
import { Column } from '../../../components/layout/column/Column';
import { MarginBottom } from '../../../components/layout/margin/MarginBottom';
import { DateTimepicker } from '../../../components/pickers/datetime_picker/DateTimepicker';
import { asyncMap, generateId } from '../../../utils/Utils';
import { DurationPicker } from '../../../components/pickers/duration_picker/DurationPicker';
import ScheduledActivityViewer from '../ScheduledActivityCardModule/ScheduledActivityViewer';
import { ActivityViewer } from '../../../components/ActivityViewer/ActivityViewer';
import { GridItem } from '../ReactGridModule/GridItem';

type TRowsData = { activity: Activity, scheduledActivities: ScheduledActivity[] }[];

export interface IActivityTableModuleProps {
    onCreateActivity: (activity: Activity) => Promise<void>;
    onDeleteActivity: (activity: Activity) => void;
    onUpdateActivity: (activity: Activity) => void;
    onCreateScheduledActivity: (activity: ScheduledActivity) => void;
    onFinishScheduledActivity: (scheduledActivity: ScheduledActivity) => void;
    onDeleteScheduledActivity: (scheduledActivity: ScheduledActivity) => void;
    sendToTodoList: (activity: Activity) => void;
    rowData?: TRowsData;
    startDate: string;
    endDate: string;
}

export class ActivityTableModule extends BaseModule {
    onSubscribe: Function;
    onCreateActivity;
    onDeleteActivity;
    onUpdateActivity;
    onFinishScheduledActivity;
    onDeleteScheduledActivity;
    onCreateScheduledActivity;
    sendToTodoList;
    rowData: TRowsData;
    startDate: string;
    endDate: string;
    dataContextArgs?: string[];

    constructor(
        baseProps: IBaseModule,
        activityTableModuleProps: IActivityTableModuleProps
    ) {
        super(baseProps);
        // console.log(activityTableModuleProps.onCreateScheduledActivity);
        Object.assign(this, activityTableModuleProps);
        this.rowData = activityTableModuleProps.rowData || [];
        this.autoPackingDimensions = { w: 24, h: 30 };
        this.dataTestid = 'activity-table-module';
        this.dataContextArgs = ["activities.activities", "calendarData.calendarData", "scheduledActivities.scheduledActivities"]
    }

    onDataContextUpdate = async (dataContext: TData) => {
        if (dataContext?.activities?.activities?.length) {
            this.rowData = await asyncMap(dataContext.activities.activities,
                async activity => ({
                    activity,
                    scheduledActivities: await dataContext.calendarData.getAllScheduledActivitiesOfTypeInTimeSpan(activity.name, this.startDate, this.endDate, dataContext, false, false)
                }));
            // console.log(this.rowData);
            this.forceRender();
        }
    }

    actionMenu?(appContext: any, dataContext: TData): JSX.Element[] {
        return null;
    }

    updateModuleFirebase() {
        const firebaseData: Omit<IBaseModule, "onDelete"> = { id: this.id, user: this.user, type: this.type, name: this.name }
        writeData(getModulesPath(this.user, this.id), firebaseData);
    }

    getCreationDialog(): JSX.Element {
        return <div>creation dialog</div>
    }

    renderTopMenu(): JSX.Element {
        return <Row>
            <div
                className="center-content"
                data-testid="activity-table-create-activity-button"
                role="button"
                onClick={() => {
                    this.toggleModal(
                        <QuickActivityCreator
                            testId={'activity-creator'}
                            onCreate={async (activity: Activity) => {
                                this.rowData.push({ activity, scheduledActivities: [] })
                                this.onCreateActivity(activity);
                                this.closeModal()
                            }} />
                    )
                }}>
                Skapa ny aktivitet
                <PlusIcon />
            </div>
        </Row >
    }
    customDelete = (dataContext: TData): void => {
        dataContext.modules.deleteModule(this);
    }

    getDefaultGrid(): ILayouts {
        return {
            id: this.id,
            [breakpointTypes.SM]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.HALF,
                h: GridHeightsSM.FULL,
            }, this.id),
            [breakpointTypes.LG]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsLG.HALF,
                h: GridHeightsLG.FULL,
            }, this.id)
        }
    }

    setRows = (rowsData: TRowsData): IRow[] => {

        const getRowData = (scheduledActivities: ScheduledActivity[]): {
            lastCompleted: ScheduledActivity,
            oldestOpen: ScheduledActivity,
            timeLeftToStart: number
        } => {
            scheduledActivities.sort((a, b) => a.startTime < b.startTime ? 1 : -1);
            const allCompleted: ScheduledActivity[] = scheduledActivities.filter(s => s.status === ActivityStatus.DONE);
            const lastCompleted = allCompleted[0];
            const allOpen = scheduledActivities.filter(s => s.status !== ActivityStatus.DONE);
            const oldestOpen: ScheduledActivity = allOpen[0];
            const timeLeftToStart = oldestOpen?.startTime ? oldestOpen.startTime - getNow() : 0
            return { lastCompleted, oldestOpen, timeLeftToStart };
        }

        const getRows = (rowsData: TRowsData): IRow[] => {

            const rows: IRow[] = rowsData.map(rowData => {
                const { lastCompleted, timeLeftToStart, oldestOpen } = getRowData(rowData.scheduledActivities);
                const row = {
                    id: rowData.activity.id,
                    cells: [
                        {
                            columnId: "name", content: <div data-testid="cell-content" onClick={() => {
                                this.toggleModal(
                                    <ActivityViewer
                                        activity={rowData.activity}
                                        sendToTodoList={this.sendToTodoList}
                                        onUpdateActivity={this.onUpdateActivity}
                                        onDeleteActivity={(activity: Activity) => {
                                            this.rowData = this.rowData.filter(row => row.activity.id !== activity.id);
                                            this.onDeleteActivity(activity);
                                            this.closeModal();
                                        }}
                                        onCreateScheduledActivity={this.onCreateScheduledActivity}
                                    />
                                )
                            }}>{rowData.activity.name || "Error"}</div>
                        },
                        { columnId: "interval", content: rowData.activity.interval ? getDuration(rowData.activity.interval) : "" },
                        { columnId: "lastCompleted", content: lastCompleted?.stopTime ? getDuration(getNow() - lastCompleted.stopTime) : "" },
                        { columnId: "dueTime", content: timeLeftToStart, extra: { timeLeftToStart, oldestOpen } },
                        // { columnId: "timeLeftInterval", content: test },
                        {
                            columnId: "complete", content: oldestOpen ? <Button size="small" dataTestid="complete-last" onClick={() => this.onFinishScheduledActivity(oldestOpen)} label={'OK'} /> : ""
                        },
                        {
                            columnId: "removeScheduled", content: timeLeftToStart ? <Button label="OK" dataTestid="remove-last" size="small" onClick={() => this.onDeleteScheduledActivity(oldestOpen)} /> : null
                        },
                    ],
                    rowStyling: { color: 'black' }
                }
                return row;
            })
            return rows;

        }
        const rws: IRow[] = getRows(rowsData);
        return rws;
    }

    renderTable = (rows: IRow[]) => <Table
        testId='activity-table'
        className="activity-table"
        customSort={{ colId: "dueTime", asc: false }}
        // mobileCells={["schedule", "name", "dueTime"]}
        settingsId={''}
        columns={[
            // { id: "status", label: "Status" },
            { id: "name", label: "Namn" },
            { id: "interval", label: "Intervall" },
            { id: "lastCompleted", label: "Senast slutförd" },
            {
                id: "dueTime", label: "Tid kvar",
                formatter: (row: IRow, cell: ICell, extra?: any) => {
                    return <div style={{ color: extra?.timeLeftToStart < 0 ? "red" : "green" }}>{extra?.timeLeftToStart ? getDuration(extra.timeLeftToStart) : ""}</div>;
                },
                onClickCellModal: async (row, cell, extra) => {
                    if (extra) {
                        // return <ScheduledActivityViewer
                        //     onStartTimeUpdate={(...props) => {
                        //         console.log(props)
                        //     }}
                        //     dataTestid="activity-table-module"
                        //     scheduledActivity={extra.oldes}
                        //     onDelete={this.onDeleteScheduledActivity}
                        //     onSendToTodoList={undefined}
                        // />
                        return <ScheduledActivityViewer
                            scheduledActivity={extra.oldes}
                            dataTestid='activity-table-module'
                            onDeleteScheduledActivity={this.onDeleteScheduledActivity}
                            onUpdateScheduledActivity={this.onFinishScheduledActivity}
                        />
                    }
                    return <div>hej</div>
                },
            },
            // { id: "timeLeftInterval", label: "Tid kvar till intervall" },
            { id: "complete", label: "Klar" },
            { id: "removeScheduled", label: "Ta bort senast schedulerade" },
        ]}
        rows={rows} />

    render(): JSX.Element {
        const rows = this.setRows(this.rowData) || [];
        return this.renderTable(rows)
    }

    toFirebaseObject() {

    }

}