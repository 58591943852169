
import * as React from 'react'
import { breakpoints, breakpointTypes, cols } from '../../../utils/constants';
import "../../../components/layouts/ReactGridLayout.scss";

import { Responsive, WidthProvider } from "react-grid-layout";
import { GridItem } from './GridItem';
import { BaseModule } from '../BaseModule';
import { Layout } from '../../Layout';

interface IReactGrid {
    dataTestid?: string;
    id: string;
    // elements: JSX.Element[];
    layout: Layout;
    modules: BaseModule[];
    onDragStart: (newGrid: any) => void;
    onDragStop: (newGrid: any[], modules: any[]) => void;
    onDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
    onResizeStop: (layout: any[]) => void;
    testDraggable?: boolean;
}

const ResponsiveGridLayout = WidthProvider(Responsive)

const ReactGrid = (props: IReactGrid) => {
    const { dataTestid, id, layout, modules, onDragStart, onDragStop, onDragOver, onResizeStop, testDraggable } = props;

    const moduleToElement = (module: BaseModule): JSX.Element => {
        const grid = layout.getAllGrids(breakpointTypes.LG).find(g => g.i === module.id)
        const gridItem = <div
            data-testid="grid-item"
            key={module.id}
            data-grid={{ ...grid, isDraggable: testDraggable ?? grid.isDraggable ?? true }}
            className={`moduleid-${module.id} ${module.id} ${id}`}
        >
            {module.renderModule(id)}
        </div>
        return gridItem;
    }

    const layoutToRenderedElements = (gridItems: GridItem[], modules: BaseModule[]): JSX.Element[] => {
        layout.updateGridItems(gridItems, breakpointTypes.LG,);

        if (layout && modules) {
            const grids: JSX.Element[] = [];
            for (const module of modules) {
                const gridItem = moduleToElement(module);
                if (Object.keys(gridItem).length) {
                    grids.push(gridItem);
                }
            }
            return grids;
        }
        else {
            return;
        }
    }

    if(!layout || !modules){
        return <div>no layout or modules</div>
    }
    const elements = layoutToRenderedElements(layout.getAllGrids(breakpointTypes.LG), modules);
    if (!elements?.length) {
        return <div>no items :/</div>
    }

    return (<div
        className={`react-grid-layout-module pageloader-container dashboard__modules layout-${id}`}
        data-testid={dataTestid || "layout"}
    >
        <ResponsiveGridLayout
            draggableCancel=".prevent-drag"
            draggableHandle={`.${id}`}
            className={`layout`}
            breakpoints={breakpoints}
            cols={cols}
            onDropDragOver={onDragOver}
            // layout={elements}
            rowHeight={10}
            onResizeStop={onResizeStop}
            onDragStart={onDragStart}
            onDragStop={onDragStop}
            isDroppable={true}
            style={{ width: '100%', height: '700px' }}
            onDrop={() => {
                // onDrop
            }}
        >
            {elements}
        </ResponsiveGridLayout >
    </div >
    )
}

export { ReactGrid }