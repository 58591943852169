import * as React from 'react'
import { IBaseModule } from "../BaseModule";
import { GridHeightsSM, GridWidthsSM, ILayouts, breakpointTypes, modules } from '../../../utils/constants';
import { CardModule } from '../CardModule/CardModule';
import { generateId } from '../../../utils/Utils';
import { PlusIcon } from '../../../icons/PlusIcon';
import InputConfirm from '../../../components/input_and_confirm/InputConfirm';
import { Row } from '../../../components/layout/row/Row';
import { Column } from '../../../components/layout/column/Column';
import { IReactGridModuleProps, ReactGridModule } from '../ReactGridModule/ReactGridModule';
import { ScheduledActivityCardModule } from '../ScheduledActivityCardModule/ScheduledActivityCardModule';
import { ScheduledActivity } from '../../../components/activity_generator/ScheduledActivity';
import "./BoardModule.scss";
import { GridItem } from '../ReactGridModule/GridItem';

export interface IBoardModuleProps extends IReactGridModuleProps {
    onCreateCard?: (cardModule: CardModule | ScheduledActivityCardModule, boardModule: BoardModule) => void;
}

export class BoardModule extends ReactGridModule {
    onCreateCard: (cardModule: CardModule | ScheduledActivityCardModule, boardModule: BoardModule) => void;

    constructor(baseProps: IBaseModule, boardModuleProps: IBoardModuleProps) {
        super(baseProps, boardModuleProps);
        this.onCreateCard = boardModuleProps.onCreateCard;
        // Object.assign(this, boardModuleProps);
        this.noBox = false;
        this.dataTestid = `board-module`;
    }

    cardArrayToObject = (cardArray: GridItem[]): { [key: string]: GridItem } => {
        return cardArray.reduce((previous, current, index, array) => {
            previous[current.i] = current;
            return previous;
        }, {})
    }

    getCreationDialog(): JSX.Element {
        return <div>
            {/* <InputConfirm label='Namn på lista' okButtonTestid='boardok' onConfirm={name => {
                onCreate(name);
            }} /> */}
        </div>
    }

    getDefaultGrid(): ILayouts {
        return {
            id: this.id,
            [breakpointTypes.SM]: new GridItem(({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.FULL,
                h: GridHeightsSM.FULL,
            }), this.id),
            [breakpointTypes.LG]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.QUARTER,
                h: GridHeightsSM.HALF,
            }, this.id)
        }
    }

    getBoardItemType(name: string): CardModule {
        const baseProps = { id: generateId(), name, type: modules.CARD, user: this.user, parentLayoutId: "", onDelete: () => {} }
        const cardModule = new CardModule(
            baseProps,
            { 
                onCardCreate: () => console.log(),
                onCardDelete: () => console.log()
             }
        )
        return cardModule;
    }

    createCardModule(name: string, scheduledActivity?: ScheduledActivity): CardModule | ScheduledActivityCardModule {
        const cardModule = this.getBoardItemType(name);
        return cardModule
    }

    createBoardItem(name: string) {
        const cardModule: CardModule = this.createCardModule(name);
        this.addModule(cardModule);
        this.renderModule();
        this.closeModal();
        this.onCreateCard(cardModule, this);
    }

    deleteBoardItem = (id: string) => {
        super.removeModule(id);
    }

    renderTopMenu = (): JSX.Element => {
        return <Column alignLeft className='board-top'>
            <Row>
                <div
                    data-testid='add-card-button'
                    className="center-content"
                    role="button"
                    onClick={(e) => {
                        this.toggleModal(
                            <div>
                                <InputConfirm testId='add-board-card' label='Namn på kortet' onConfirm={name => {
                                    this.createBoardItem(name);
                                }} /></div>)
                    }}>
                    Lägg till kort
                    <PlusIcon />
                </div>
            </Row>
        </Column>
    }

    render(): JSX.Element {
        return <div className="board-module">
            {super.render()}
        </div>
    }

}