import * as React from 'react';
import { Column } from '../../../components/layout/column/Column';
import { Button } from '../../../components/button/Button';
import { DateTimepicker } from '../../../components/pickers/datetime_picker/DateTimepicker';
import { ScheduledActivity } from '../../../components/activity_generator/ScheduledActivity';
import { addHours } from 'date-fns';
import Modal from '../../../components/modal/Modal';
import { convertTimestampToStringDate, convertTimestampToStringDateAndTime } from '../../../utils/TimeUtils';
import { MarginBottom } from '../../../components/layout/margin/MarginBottom';

const ScheduledActivityViewer = ({ scheduledActivity, onDeleteScheduledActivity, onUpdateScheduledActivity, dataTestid, hideHeader }: { scheduledActivity: ScheduledActivity, onDeleteScheduledActivity: Function, onUpdateScheduledActivity: Function, dataTestid?: string, hideHeader?: boolean }) => {
    const [showModal, setShowModal] = React.useState(false);
    const [previous, setPrevious] = React.useState(0);
    
    return (
        <Column alignLeft dataTestid={dataTestid}>
            {!hideHeader &&  <h1>{scheduledActivity?.content}</h1>}
            <Button dataTestid='planned-starttime' label={'Planerad starttid för aktiviteten'} onClick={() => { setShowModal(true) }} />
            <MarginBottom />
            <Button label={'Radera aktivitet'} onClick={() => {
                onDeleteScheduledActivity();
                setShowModal(false);
            }} />
            <MarginBottom />
            Startas senast: {scheduledActivity.startTime > 0 && convertTimestampToStringDateAndTime(scheduledActivity.startTime)}
            <Modal showModal={showModal} setShowModal={setShowModal}>
                <>
                    {
                        <DateTimepicker
                            defaultTime={addHours(new Date(), 1)}
                            separateTimeAndDate
                            onChange={newTime => {
                                const test = scheduledActivity.startTime;
                                setPrevious(test);
                                scheduledActivity.startTime = newTime.getTime()
                            }}
                        />
                    }
                    <Button dataTestid="confirm-button" label={'Bekräfta'} onClick={() => {
                        const previousDate = previous > 0 ? convertTimestampToStringDate(previous) : 0;
                        onUpdateScheduledActivity(scheduledActivity, previousDate);
                        setShowModal(false);
                    }} />
                </>
            </Modal>
        </Column>
    );
};

export default ScheduledActivityViewer;