import * as React from 'react';
import { BoardModule, IBoardModuleProps } from '../BoardModule/BoardModule';
import { CardModule, ICardModuleProps } from '../CardModule/CardModule';
import { IScheduledActivityCardModuleProps, ScheduledActivityCardModule } from '../ScheduledActivityCardModule/ScheduledActivityCardModule';
import { ActivityStatus, modules } from '../../../utils/constants';
import { generateId } from '../../../utils/Utils';
import { ScheduledActivity } from '../../../components/activity_generator/ScheduledActivity';
import InputConfirm from '../../../components/input_and_confirm/InputConfirm';
import { Column } from '../../../components/layout/column/Column';
import { Row } from '../../../components/layout/row/Row';
import { PlusIcon } from '../../../icons/PlusIcon';
import { ActivityPicker } from '../../../components/pickers/activity_picker/ActivityPicker';
import { Activity } from '../../../data/activity/Activity';
import { TData } from '../../../app/interface';
import { IBaseModule } from '../BaseModule';

interface ITodoBoardModuleProps {
    activities: Activity[];
    onUpdateScheduledActivity: (updatedScheduledActivity: ScheduledActivityCardModule) => void;
    onDeleteScheduledActivity: (removedModule: CardModule | ScheduledActivityCardModule, todoBoardModule: TodoBoardModule) => void;
    onRemoveChildModule: (removedModule: CardModule | ScheduledActivityCardModule) => void;
}

export class TodoBoardModule extends BoardModule {
    activities: Activity[];
    dataTestid = "todo-board-module";
    dataContextArgs?: string[];
    onUpdateScheduledActivity: (updatedScheduledActivity: ScheduledActivityCardModule) => void;
    onDeleteScheduledActivity: (removedModule: CardModule | ScheduledActivityCardModule, todoBoardModule: TodoBoardModule) => void;
    // onRemoveChildModule: (removedModule: CardModule | ScheduledActivityCardModule) => void;

    constructor(
        baseProps: IBaseModule,
        boardModuleProps: IBoardModuleProps,
        todoBoardModuleProps: ITodoBoardModuleProps
    ) {
        super(baseProps, boardModuleProps);
        this.dataContextArgs = ["activities.activities"]
        Object.assign(this, todoBoardModuleProps)
        this.modules.forEach(m => {
            if (m instanceof ScheduledActivityCardModule) {
                m.onUpdateScheduledActivity = (updatedScheduledActivity: ScheduledActivityCardModule) => {
                    this.onUpdateScheduledActivity(updatedScheduledActivity);
                }

                // m.onDelete = (cardModule: CardModule | ScheduledActivityCardModule) => {
                //     m.closeModal();
                //     this.removeModule(cardModule.id);
                //     this.onDeleteScheduledActivity(cardModule, this);

                // }
            }
        })
    }

    async onDataContextUpdate(dataContext: TData) {
        this.activities = dataContext?.activities?.activities || [];
    }

    getCustomCardFront = (card: CardModule) => {
        // return <CustomCardFront todoBoardModule={this} card={card} />
    }

    createScheduledActivity = (id: string, content: string, status: ActivityStatus, activityId?: string, startTime?: number) => new ScheduledActivity({ id, content, status, activityId, startTime });

    createCardModule(baseProps, scheduledActivity: ScheduledActivity): CardModule | ScheduledActivityCardModule {
        const cardModuleProps: ICardModuleProps = {
            onCardCreate: (cardModule: CardModule) => { console.log("Card Created", cardModule) },
            onCardDelete: (cardModule: CardModule) => { console.log("Card Deleted", cardModule) },
        }
        const scheduledActivityCardModuleProps: IScheduledActivityCardModuleProps = {
            scheduledActivity,
            onUpdateScheduledActivity: async (updatedScheduledActivity: ScheduledActivityCardModule) => {
                this.onUpdateScheduledActivity(updatedScheduledActivity);
            },
            // onDelete: async (cardModule: ScheduledActivityCardModule) => {
            //     this.removeModule(cardModule.id);
            // }
        }
        return new ScheduledActivityCardModule(
            baseProps,
            cardModuleProps,
            scheduledActivityCardModuleProps
        )
    }

    createBoardItem(name: string) {
        const baseProps = { id: generateId(), name, type: modules.SCHEDULED_ACTIVITY_CARD, user: this.user }
        const scheduledActivity = this.createScheduledActivity(baseProps.id, baseProps.name, ActivityStatus.NOT_STARTED)
        const ScheduledActivityCardModule: ScheduledActivityCardModule = this.createCardModule(baseProps, scheduledActivity) as ScheduledActivityCardModule;
        this.addModule(ScheduledActivityCardModule);
        this.renderModule();
        this.closeModal();
        this.onCreateCard(ScheduledActivityCardModule, this);
    }

    createBoardItemFromActivity = (activity: Activity) => {
        const baseProps = { id: generateId(), name: activity.name, type: modules.SCHEDULED_ACTIVITY_CARD, user: this.user }
        const scheduledActivity = this.createScheduledActivity(baseProps.id, baseProps.name, ActivityStatus.NOT_STARTED, activity.id)
        const ScheduledActivityCardModule: ScheduledActivityCardModule = this.createCardModule(baseProps, scheduledActivity) as ScheduledActivityCardModule;
        this.addModule(ScheduledActivityCardModule);
        this.renderModule();
        this.closeModal();
        this.onCreateCard(ScheduledActivityCardModule, this);
    }

    renderTopMenu = (): JSX.Element => {
        return <Column alignLeft className='board-top'>
            <Row>
                <div
                    data-testid='add-card-button'
                    className="center-content"
                    role="button"
                    onClick={(e) => {
                        this.toggleModal(
                            <InputConfirm testId='add-board-card' label='Namn på kortet' onConfirm={name => {
                                this.createBoardItem(name);
                            }} />
                        )
                    }}>
                    Lägg till en temporär aktivitet
                    <PlusIcon />
                </div>
                <div
                    data-testid='add-existing-activity-button'
                    className="center-content"
                    role="button"
                    onClick={(e) => {
                        this.toggleModal(
                            <ActivityPicker testId="activity-picker" activities={this.activities} onSelect={this.createBoardItemFromActivity} />
                        )
                    }}>
                    Lägg till befintlig aktivitet
                    <PlusIcon />
                </div>
            </Row>
        </Column>
    }



}