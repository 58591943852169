import { IRule, ISchedulePosition } from "../../components/activity_generator/interfaces";
import { ScheduledActivity } from "../../components/activity_generator/ScheduledActivity";
import { ActivityStatus, firebaseModulePaths } from "../../utils/constants";
import { deleteData, writeData } from "../../utils/Firebase";
import { getToday, getNow, convertTimestampToStringDateAndTime, getDuration } from "../../utils/TimeUtils";
import { generateId } from "../../utils/Utils";
import { IBaseData } from "../interfaces/BaseDataInterface";

export enum AddPoints {
    ADD = 'Lägg till poäng',
    SUBTRACT = 'Dra av poäng',
    FIXED = 'Fixed bonus',
    NONE = 'None'
};

interface ICurrentlyChainedActivities {
    [key: string]: { id: string, status: ActivityStatus }[]
}

export interface IActivity extends IBaseData {
    created: number;
    rules?: IRule[];
    addPoints?: AddPoints;
    addTimes?: number;
    schedulePosition?: ISchedulePosition;
    schedule?: IActivity[];
    status?: ActivityStatus;
    parentActivities?: Activity[];
    childActivities?: Activity[];
    chainedActivities?: Activity[];
    rootActivities?: Activity[];
    leafActivities?: Activity[];
    interval?: number;
    defaultTimer?: boolean;
    currentlyChainedActivities?: ICurrentlyChainedActivities;
    autoScheduleTime?: number;
    active?: boolean
}

export const getDefaultActivity = (activityName = ""): Activity => {
    const props: IActivity = {
        id: generateId(), created: new Date().getTime(), status: ActivityStatus.NOT_STARTED, name: activityName, rules: [], addPoints: AddPoints.NONE, addTimes: 1, schedulePosition: {}, schedule: [], parentActivities: [], childActivities: [], rootActivities: [], leafActivities: [],
        currentlyChainedActivities: {},
    }
    const defaultActivityInstance: Activity = new Activity(props)
    return defaultActivityInstance;
}

export class Activity implements IActivity {
    name: string;
    id: string;
    created: number;
    rules?: IRule[];
    addPoints?: AddPoints;
    addTimes?: number;
    schedulePosition?: ISchedulePosition;
    schedule?: IActivity[];
    status?: ActivityStatus;
    parentActivities?: Activity[];
    childActivities?: Activity[];
    chainedActivities?: Activity[];
    rootActivities?: Activity[];
    leafActivities?: Activity[];
    interval?: number;
    fixedBonusPoints?: number;
    fixedPenaltyPoints?: number;
    defaultTimer?: boolean;
    currentlyChainedActivities?: { [key: string]: { id: string, status: ActivityStatus }[] }
    autoScheduleTime?: number;
    active?: boolean;
    tags?: string[];

    constructor(activityProps: IActivity) {
        Object.assign(this, activityProps);
    }


    addChild(name: string, rootActivities: Activity[]) {
        const newChildActivity: Activity = getDefaultActivity(name)
        newChildActivity.parentActivities.push(this);
        this.childActivities.push(newChildActivity);
        for (const rootActivity of rootActivities) {
            rootActivity.leafActivities.push(newChildActivity);
            rootActivity.leafActivities = rootActivity.leafActivities.filter(l => l.id !== this.id)
            newChildActivity.rootActivities = this.rootActivities;
        }
        return newChildActivity;
    }

    getDepth(currentDepth = 0): number {
        if (!this.isRoot()) {
            currentDepth++;
            return this.parentActivities[0].getDepth(currentDepth);
        }
        else if (this.isRoot()) {
            return currentDepth;
        }
        else {
            return -1;
        }
    }

    // postpone(time: number) {
    //     this.setDueTime(this.dueTime + time);
    // }

    // getLastCompleted() {
    //     return (this.lastCompleted || this.created);
    // }

    // getDueTime() {
    //     const dueTime = this?.interval < 1 ? 0 : (this.getLastCompleted() + this.interval);
    //     return dueTime || 0;
    // }

    setName(name: string) {
        this.name = name;
    }

    addChainedActivity(activity: Activity) {
        if (!this.chainedActivities) {
            this.chainedActivities = [];
        }
        if (!this.chainedActivities.some(a => a.id === activity.id)) {
            this.chainedActivities.push(activity);
        }
    }

    getTotalTimeOfCurrentlyChainedScheduledActivities(sessionId: string, getScheduledActivity: Function): number {
        let totalTime = 0;
        for (const s of this.currentlyChainedActivities[sessionId]) {
            const fromDate = getToday();
            const toDate = getToday();
            const scheduledActivity: ScheduledActivity = getScheduledActivity(fromDate, toDate, s.id)
            totalTime += scheduledActivity.totalTime;
        }
        return totalTime;
    }

    formatDate(millis: number): string {
        if (millis <= 0) {
            return "";
        }
        const time = convertTimestampToStringDateAndTime(millis)
        return time.slice(2, time.length);
    }

    // printLastCompleted() {
    //     return this.formatDate(this.lastCompleted);
    // }

    // async populateChainedScheduledActivities(user: string, createScheduledActivityAndAddToSchedule: Function) {
    //     const sessionId = generateId();
    //     this.currentlyChainedActivities[sessionId] = [];
    //     for (const a of this.chainedActivities) {
    //         const scheduledActivity: ScheduledActivity = new ScheduledActivity({
    //             id: generateId(),
    //             date: 
    //             content: a.name,
    //             status: ActivityStatus.NOT_STARTED,
    //             timed: a.defaultTimer,
    //             startTime: 0,
    //             lastStartTime: 0,
    //             stopTime: 0,
    //             totalTime: 0,
    //             dueTime: convertTimestampToStringDate(a.dueTime) <= getToday() ? getTimestampFromStringDateAndTime(getToday(), "23:59:59") : a.dueTime,
    //             extra: { activityId: a.id },
    //         })
    //         // scheduledActivity.activitySessionIds = { activityId: this.id, activitySessionId: sessionId }
    //         this.currentlyChainedActivities[sessionId].push({ id: scheduledActivity.id, status: ActivityStatus.NOT_STARTED })
    //         await createScheduledActivityAndAddToSchedule(this, scheduledActivity);
    //     }
    //     console.log(this.currentlyChainedActivities[sessionId]);
    //     console.log(this);
    //     this.updateFirebase(user);
    // }

    finish(user: string) {

        // if (!this.timesCompleted) {
        //     this.timesCompleted = 0
        //     this.totalTime = 0
        // }
        // this.timesCompleted++
        // this.totalTime = (this.totalTime || 0) + totalTime
        // // this.averageTime = this.totalTime / this.timesCompleted
        // this.lastCompleted = getNow();
        // this.setDueTime();
        // this.updateFirebase(user);
        // setActivityPoints(this, logMessage || `${this.name} finished`, pointsOnFinish);
    }

    // async getAverageCompletionTime(dataContext: TData): Promise<number> {
    //     let allScheduledActivities: ScheduledActivity[] = await this.getAllScheduledActivitiesOfThisType(dataContext)
    //     allScheduledActivities = allScheduledActivities.filter(s => s?.totalTime > 0);
    //     const totalTime = allScheduledActivities.map(scheduledActivity => scheduledActivity.totalTime).reduce((previous, current) => {
    //         return previous + current;
    //     }, 0);

    //     if (!allScheduledActivities?.length || totalTime <= 0) {
    //         return 0
    //     }
    //     return totalTime / allScheduledActivities.length;
    // }


    addParent(newParent: Activity, parentsRootActivities: Activity[]) {
        if (this.parentActivities.length > 0) {
            console.log('Ej tillåtet att ha mer än en förälder tills vidare. Fundera vidare på att ha flera om behov uppstår senare')
        } else {
            this.parentActivities.push(newParent);
            newParent.leafActivities = newParent.leafActivities.filter(l => l !== newParent);
            newParent.childActivities.push(this);
            this.rootActivities = this.rootActivities.filter(r => r !== this);
            const isRoot = this.rootActivities.length > 0;
            if (!isRoot) {
                this.leafActivities = [];
            }

            parentsRootActivities.forEach(root => {
                root.leafActivities = root.leafActivities.filter(l => l !== newParent)
                root.leafActivities.push(this);
                this.rootActivities.push(root)
            })
        }
    }

    removeChild(childActivity: Activity) {
        if (this.childActivities?.includes(childActivity)) {
            this.childActivities = this.childActivities.filter(c => c.id !== childActivity.id);
        }
    }

    changeParent(newParent: Activity, user: string) {
        const oldParent = this?.parentActivities[0];
        if (oldParent) {
            oldParent.removeChild(this)
            oldParent.updateFirebase(user);
        }
        this.parentActivities = [newParent];
        newParent.childActivities.push(this)
        this.updateFirebase(user);
        newParent.updateFirebase(user);
    }

    hasChildren(): boolean {
        if (this.childActivities?.length > 0) {
            return true;
        }
        return false;
    }

    // async getAllScheduledActivitiesOfThisType(dataContext: TData): Promise<ScheduledActivity[]> {
    //     if (!dataContext.calendarData?.calendarData?.length) {
    //         return []
    //     }
    //     let sch = [];
    //     for (const cd of dataContext.calendarData.calendarData) {
    //         if (cd.schedule) {
    //             const schedules: ScheduledActivity[] = await dataContext.scheduledActivities.getSchedule(cd.schedule);
    //             sch.concat(schedules);
    //         }
    //     }
    //     return sch;
    // }

    hasAncestor(activity: Activity): boolean {
        if (this.parentActivities.includes(activity)) {
            return true;
        }
        else if (!this.parentActivities?.length) {
            return false;
        }
        else {
            return this.parentActivities[0].hasAncestor(activity);
        }
    }

    // expired(date: number = new Date().getTime()): boolean {
    //     if (this.interval > 0) {
    //         const lastCompleted = this.lastCompleted || this.created;
    //         if (lastCompleted < date) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    getAllDescendants() {
        this.childActivities.forEach((childActivity: Activity) => {
            if (childActivity.hasChildren()) {

            }
        })
    }

    getParent() {
        if (this.parentActivities?.length) {
            return this.parentActivities[0].getRoot()
        }
        return null;
    }

    getRoot() {
        let parent = this.getParent();
        if (parent === null) {
            return this;
        }
        else {
            return parent.getRoot();
        }
    }

    async delete(user) {
        const activitiesPath = firebaseModulePaths.GET_ACTIVITY_MODULE_PATH(user);
        await deleteData(activitiesPath + "/" + this.id)
        if (this.childActivities) {
            if (this.isRoot()) {
                this.childActivities.forEach((childActivity: Activity) => {
                    //Sätt nya rötter för alla barn
                })
            }
        }
    }

    isRoot(): boolean {
        if (!this.parentActivities || this.parentActivities.length < 1) {
            return true;
        }
        return false;
    }

    isLeaf(): boolean {
        if (!this.childActivities || this.childActivities.length < 1) {
            return true;
        }
        return false;
    }

    convertToFirebaseObject() {
        const firebaseActivity: IActivity = {
            ...this,
        }
        //@ts-ignore
        console.log(firebaseActivity);
        // delete firebaseActivity.canAutoScheduleToday;
        return firebaseActivity;
    }

    updateFirebaseAttribute(user: string, attribute: string, value: any) {
        writeData(`${firebaseModulePaths.GET_ACTIVITY_MODULE_PATH(user, this.id)}/${attribute}`, value)
    }

    updateFirebase(user: string) {
        const firebaseActivity = this.convertToFirebaseObject();
        writeData(firebaseModulePaths.GET_ACTIVITY_MODULE_PATH(user, this.id), firebaseActivity)
    }

    // dueTimePassed(): boolean {
    //     if (this.dueTime === 0) {
    //         return false;
    //     }
    //     return this.dueTime < getNow();
    // }
    async getAllScheduledActivitiesOfTypeInSpan(dataContext, startDate, endDate): Promise<ScheduledActivity[]> {
        const allActivitiesOfTypeInSpan: ScheduledActivity[] = await dataContext?.calendarData?.getAllScheduledActivitiesOfTypeInTimeSpan(this.name, startDate, endDate, dataContext);
        return allActivitiesOfTypeInSpan;
    }

    async getFutureScheduledActivities(dataContext, startDate, endDate) {
        const allActivtiesOfTypeInSpan = await this.getAllScheduledActivitiesOfTypeInSpan(dataContext, startDate, endDate);
        return allActivtiesOfTypeInSpan.filter(a => a.startTime > getNow() && a.status !== ActivityStatus.DONE);
    }

    async getPreviousScheduledActivities(dataContext, startDate, endDate) {
        const allScheduledActivitiesOfTypeInSpan = await this.getAllScheduledActivitiesOfTypeInSpan(dataContext, startDate, endDate);
        return allScheduledActivitiesOfTypeInSpan.filter(a => a.startTime < getNow() && a.status !== ActivityStatus.DONE);
    }

    async getPreviousScheduledActivity(dataContext, startDate, endDate) {
        const previouslyScheduledActivities = await this.getPreviousScheduledActivities(dataContext, startDate, endDate);
        return previouslyScheduledActivities?.length ? previouslyScheduledActivities[previouslyScheduledActivities.length - 1] : null;
    }

    async getLastScheduledActivity(dataContext, startDate, endDate): Promise<ScheduledActivity> {
        const allScheduledActivities = await this.getAllScheduledActivitiesOfTypeInSpan(dataContext, startDate, endDate);
        allScheduledActivities.sort((a, b) => a.startTime < b.startTime ? 1 : -1);
        return allScheduledActivities?.length > 0 ? allScheduledActivities[0] : null;
    }

    async getAllCompletedScheduledActivitiesOfTypeInSpan(dataContext, startDate, endDate): Promise<ScheduledActivity[]> {
        const allActivitiesOfTypeInSpan = await this.getAllScheduledActivitiesOfTypeInSpan(dataContext, startDate, endDate);
        return allActivitiesOfTypeInSpan.filter(a => a.status === ActivityStatus.DONE).sort((a, b) => a.stopTime < b.stopTime ? 1 : -1)
    }

    async getTimesCompleted(dataContext, startDate, endDate) {
        const completedActivities = await this.getAllCompletedScheduledActivitiesOfTypeInSpan(dataContext, startDate, endDate);
        const timesCompleted = completedActivities?.length;
        return timesCompleted;
    }

    async getLastCompleted(dataContext, startDate, endDate): Promise<number> {
        const completedActivities = await this.getAllCompletedScheduledActivitiesOfTypeInSpan(dataContext, startDate, endDate);
        const lastCompleted = completedActivities[0]?.stopTime || 0;
        return lastCompleted
    }

    async getLastUncompleted(dataContext, startDate, endDate): Promise<number> {
        const completedActivities = await this.getAllScheduledActivitiesOfTypeInSpan(dataContext, startDate, endDate);
        if (this.name.includes("protein")) {
            console.log(this.name, completedActivities)
        }
        const lastCompleted = completedActivities[0]?.startTime || 0;
        return lastCompleted
    }

    async printLastCompleted(dataContext, startDate, endDate): Promise<string> {
        const lastCompleted = await this.getLastCompleted(dataContext, startDate, endDate);
        if (lastCompleted) {
            return getDuration(getNow() - lastCompleted);
        }
        return ""
    }

    async getIntervalTimes(dataContext, startDate, endDate) {
        const completedActivities = await this.getAllCompletedScheduledActivitiesOfTypeInSpan(dataContext, startDate, endDate)
        const intervalTimes = completedActivities.reduce((previousValue, currentValue, currentIndex, array) => {
            if (currentIndex === 0) {
                return [getNow() - array[currentIndex].stopTime];
            }
            const diff = array[currentIndex - 1].stopTime - array[currentIndex].stopTime;
            previousValue.push(diff);
            return previousValue;
        }, []);
        const totalIntervalTimes = intervalTimes.reduce((previousValue, currentValue) => {
            return previousValue + currentValue;
        }, 0);
        return totalIntervalTimes;
    }

    async getAverageIntervalTime(dataContext, startDate, endDate) {
        const totalIntervalTimes = await this.getIntervalTimes(dataContext, startDate, endDate);
        const timesCompleted = await this.getTimesCompleted(dataContext, startDate, endDate);
        const averageIntervalTimes = totalIntervalTimes / timesCompleted;
        return averageIntervalTimes;
    }

    async getNextActivity(dataContext, startDate, endDate): Promise<ScheduledActivity> {
        const futureActivities = await this.getFutureScheduledActivities(dataContext, startDate, endDate)
        this.getLastCompleted(dataContext, startDate, endDate);
        return futureActivities?.length ? futureActivities.sort((a, b) => a.startTime < b.startTime ? -1 : 1)[0] : null;
    }

    async getSelectedActivity(dataContext, startDate, endDate): Promise<ScheduledActivity> {
        const nextActivity = await this.getNextActivity(dataContext, startDate, endDate);
        const previousActivity = await this.getPreviousScheduledActivity(dataContext, startDate, endDate);
        const selectedActivity = nextActivity || previousActivity;
        return selectedActivity;
    }

    async getTimeLeftToStart(dataContext, startDate, endDate): Promise<number> {
        const time = await this.getLastUncompleted(dataContext, startDate, endDate);
        if (time) {
            return time - getNow();
        }
        return 0;
    }

    // async getNextInterval(dataContext, startDate, endDate): Promise<number> {
    //     const interval = this.getInterval();
    //     if (interval > 0) {
    //         const lastCompleted = await this.getLastCompleted(dataContext, startDate, endDate);
    //         if (!lastCompleted) {
    //             return 0
    //         }
    //         const nextPlannedInterval = lastCompleted + interval;
    //         return nextPlannedInterval;
    //     }
    //     return 0
    // }

    toJSON(): IActivity | {} {
        let jsoned: IActivity | {} = {};
        let toConvert = this;
        Object.getOwnPropertyNames(toConvert).forEach((prop) => {
            const val = toConvert[prop];
            // don't include those
            // if (prop === 'toJSON' || prop === 'constructor') {
            //     return;
            // }
            // if (typeof val === 'function') {
            //     jsoned[prop] = val.bind(jsoned);
            //     return;
            // }
            if (typeof val !== 'function' && prop !== 'toJSON' && prop !== 'constructor') {
                jsoned[prop] = val;
            }
        });

        // const inherited = Object.getPrototypeOf(toConvert);
        // if (inherited !== null) {
        //     //@ts-ignore
        //     Object.keys(this.toJSON(inherited)).forEach(key => {
        //         if (!!jsoned[key] || key === 'constructor' || key === 'toJSON')
        //             return;
        //         if (typeof inherited[key] === 'function') {
        //             jsoned[key] = inherited[key].bind(jsoned);
        //             return;
        //         }
        //         jsoned[key] = inherited[key];
        //     });
        // }
        return jsoned;
    }

}