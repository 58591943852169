import * as React from 'react'
import { BaseModule, IBaseModule } from '../BaseModule';
import InputConfirm from '../../../components/input_and_confirm/InputConfirm';
import { breakpointTypes, GridHeightsLG, GridHeightsSM, GridWidthsLG, GridWidthsSM, ILayouts, modules } from '../../../utils/constants';
import "./Card.scss";
import { ReactGridModule } from '../ReactGridModule/ReactGridModule';
import classNames from 'classnames';
import { BoxedModule } from '../../BoxedModule';
import { generateId } from '../../../utils/Utils';
import { GridItem } from '../ReactGridModule/GridItem';
import { ReactGrid } from '../ReactGridModule/ReactGrid';
import { Layout } from '../../Layout';

export interface ICardModuleProps {
    onCardCreate: (newCard: CardModule, fromCard?: CardModule) => void;
    onCardDelete: (cardModule: CardModule) => void;
    customCardClick?: () => void;
    customCardFront?: (cardModule: CardModule) => JSX.Element;
    parentModule?: ReactGridModule;
    layout?: Layout;
    childModules?: BaseModule[];
    parentLayoutId?: string
}

export class CardModule extends BaseModule implements ICardModuleProps {
    onCardCreate: (newCard: CardModule, fromCard?: CardModule) => void;
    onCardDelete: (cardModule: CardModule) => void;
    customCardClick?: () => void;
    customCardFront?: (cardModule: CardModule) => JSX.Element;
    parentModule?: ReactGridModule;
    layout?: Layout;
    childModules?: BaseModule[];
    parentLayoutId?: string

    constructor(baseProps: IBaseModule, cardModuleProps: ICardModuleProps) {
        super(baseProps);
        this.noBox = true;
        Object.assign(this, cardModuleProps)
    }

    getCreationDialog(onCreate: (baseModule: BaseModule) => void): JSX.Element {
        return <div>
            <InputConfirm label='Namn på kort' onConfirm={(name) => {
                this.name = name;
                this.type = modules.CARD;
                const cardModuleProps: ICardModuleProps = {
                    onCardCreate: onCreate,
                    onCardDelete: () => { }
                }
                Object.assign(this, cardModuleProps)
                onCreate(this);
            }} />
        </div>
    }

    getDefaultGrid(): ILayouts {
        return {
            id: this.id,
            [breakpointTypes.SM]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.CARD,
                h: GridHeightsSM.CARD,
            }, this.id),
            [breakpointTypes.LG]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsLG.CARD,
                h: GridHeightsLG.CARD,
            }, this.id)
        }
    }

    renderTopMenu(): JSX.Element {
        return null
    }

    customDelete = (): void => {

        this.closeModal();
    }

    addChildCard = (name: string) => {
        const newCardModule = new CardModule(
            {
                id: generateId(),
                name, type: modules.CARD,
                onDelete: () => { },
                user: this.user
            },
            {
                onCardCreate: () => {
                    this.onCardCreate(newCardModule, this);
                },
                onCardDelete: () => {
                    this.onCardDelete(newCardModule);
                }
            }
        )
        if (!this.layout) {
            this.layout = new Layout({ id: this.id, [breakpointTypes.SM]: {}, [breakpointTypes.LG]: {} });
        }
        if (!this.childModules) {
            this.childModules = []
        }
        newCardModule.parentLayoutId = this.id;
        this.childModules.push(newCardModule)
        this.layout.addGrid({ id: newCardModule.id, [breakpointTypes.SM]: { i: newCardModule.id, x: 0, y: 0, w: GridWidthsSM.CARD, h: GridHeightsSM.CARD }, [breakpointTypes.LG]: { i: newCardModule.id, x: 0, y: 0, w: GridWidthsLG.CARD, h: GridHeightsLG.CARD } })
        this.onCardCreate(newCardModule, this)
    }

    createCardDialog = (): JSX.Element => {

        this.childModules = this.childModules?.map((childModule: BaseModule) => {
            (childModule as CardModule).onCardCreate = this.onCardCreate;
            return childModule;
        }) || []
        return <>
            <InputConfirm
                label='Namn på kort'
                onConfirm={name => {
                    this.addChildCard(name)
                    this.closeModal();
                    this.toggleModal(this.createCardDialog())

                }}
            />{
                this.layout?.id && <ReactGrid
                    id={this.layout.id}
                    layout={this.layout}
                    modules={this.childModules}
                    onDragStart={function (newGrid: any): void {

                    }}
                    onDragStop={function (newGrid: any[], modules: any[]): void {

                    }}
                    onDragOver={function (e: React.DragEvent<HTMLDivElement>): void {

                    }}
                    onResizeStop={function (layout: any[]): void {

                    }} />}
        </>
    }


    showCardContainer = () => {
        this.toggleModal(<BoxedModule
            testId={`card-container-${this.id}`}
            moduleId={''}
            moduleName={this.name}
            onDelete={() => {
                // this.onDelete && this.onDelete(this)
                console.log("*****************delete*****************")
                this.onDelete(this);
            }}
            dragHandle={''}
            onHeaderNameUpdate={(name: string) => {

            }}
            content={<div>
                {this.createCardDialog()}
            </div>}
        />
        )
    }

    render(): JSX.Element {
        return <div
            className={classNames(
                `card`,
                this.parentLayoutId,
                this.parentModule?.id === "LayoutStartPoint" && 'card-no-parent'
            )}
        >
            <div
                data-testid="card-click-area"
                className="prevent-drag"
                onClick={() => {
                    this.showCardContainer()
                }}
            >
                {this.name}
            </div>
        </div>
    }

    toFirebaseObject() {
        return { id: this.id, name: this.name, type: this.type, layout: this.layout?.getILayout() }
    }

}