import * as React from 'react';
import { BaseModule } from '../module/modules/BaseModule';
import { Button } from '../components/button/Button';
import { generateId } from '../utils/Utils';
import { breakpointTypes, modules } from '../utils/constants';
import { CardModule } from '../module/modules/CardModule/CardModule';
import { ReactGridModule } from '../module/modules/ReactGridModule/ReactGridModule';
import { Column } from '../components/layout/column/Column';
import { MarginBottom } from '../components/layout/margin/MarginBottom';

const IsolatedEnvironment = ({ testModule, width = "600px", testButton }: { testModule: BaseModule, width?: string, testButton?: boolean }) => {

    if (!testModule) {
        return <div>Module is missing</div>
    }

    return (<div style={{ width }}>
        {testButton && <Column alignLeft>
            <Button label={'Lägg till kort i layouten'} onClick={() => {
                (testModule as ReactGridModule).addModule(
                    new CardModule(
                        { id: generateId(), name: "TestKortModul", type: modules.CARD, user: "this.user", onDelete: () => {} },
                        {
                            onCardCreate: () => console.log("Card Created"),
                            onCardDelete: (cardModule: CardModule) => { console.log("Card Deleted", cardModule) }
                        }
                    ));
            }} />
            <MarginBottom />
            <Button label={'Ta bort modul i layouten'} onClick={() => {
                const grid = (testModule as ReactGridModule).layout.getAllGrids(breakpointTypes.LG)[0];
                (testModule as ReactGridModule).removeModule(grid.i);
            }} />
            <MarginBottom />
            <Button label={'test'} onClick={() => {
                // document.getElementsByClassName()
            }} />
            <MarginBottom />
        </Column>
        }

        {testModule.renderModule(undefined, undefined, true)}
    </div>);
};

export { IsolatedEnvironment };