import "./styles.scss"
import "react-grid-layout/css/styles.css"
// import "react-resizable/css/styles.css"
import "react-resizable/css/styles.css"

import { TData } from "../../../app/interface";
import { BaseModule, IBaseModule } from "../BaseModule";
import { breakpointTypes, getModulesPath, modules } from "../../../utils/constants";
import { ILayout, Layout } from "../../Layout";
import { generateId } from "../../../utils/Utils";
import { CardModule } from "../CardModule/CardModule";
import { writeData } from "../../../utils/Firebase";
import { ScheduledActivityCardModule } from "../ScheduledActivityCardModule/ScheduledActivityCardModule";
import { GridItem } from "./GridItem";
import { ReactGrid } from "./ReactGrid";



export interface IReactGridModuleProps {
    layout?: ILayout;
    testDraggable?: boolean;
    modules?: BaseModule[];
    onDragStart: (newGrid: GridItem) => void;
    onDragStop: (newGrid: GridItem[], modules: BaseModule[]) => void;
    onDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
    onResizeStop: (layout: GridItem[]) => void;
    onRemoveModule: (module: BaseModule, gridModule: ReactGridModule) => void;
}

export class ReactGridModule extends BaseModule {
    modules: BaseModule[] = [];
    layout?: Layout;
    testDraggable?: boolean;
    onDragStart: (newGrid: GridItem) => void;
    onDragStop: (newGrid: GridItem[], modules: BaseModule[]) => void;
    onDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
    onResizeStop: (layout: GridItem[]) => void;
    onRemoveModule: (moduleId: BaseModule, gridModule: ReactGridModule) => void;

    constructor(baseProps: IBaseModule, reactGridModuleProps: IReactGridModuleProps) {
        super(baseProps);
        Object.assign(this, reactGridModuleProps);
        this.layout = new Layout(reactGridModuleProps.layout);
        this.modules = reactGridModuleProps.modules || [];
        this.testDraggable = reactGridModuleProps.testDraggable;
        this.modules.forEach(m => {
            if (m instanceof CardModule || m instanceof ScheduledActivityCardModule) {
                m.parentModule = this
            }
        });
        this.noBox = true;
    }

    addModule = (module: BaseModule, customGrid?: Partial<GridItem>) => {
        const grid: GridItem = new GridItem({ x: 0, y: 0, w: 4, h: 4, i: module.id, ...customGrid }, this.id)
        if (!this.layout) {
            this.layout = new Layout({ id: this.id, [breakpointTypes.SM]: { [grid.i]: grid }, [breakpointTypes.LG]: { [grid.i]: grid } });
        }
        else {
            this.layout[breakpointTypes.LG][grid.i] = grid;
        }
        if (module instanceof CardModule) {
            module.parentModule = this;
        }
        this.modules.push(module);
        this.forceRender && this.forceRender();
    }

    public removeModule(moduleId: string) {
        this.layout.removeGrid(moduleId);
        const moduleToRemove = this.modules.find(m => m.id === moduleId);
        this.modules = this.modules.filter(m => m.id !== moduleId);
        this.onRemoveModule && this.onRemoveModule(moduleToRemove, this);
        this.forceRender && this.forceRender();
    }

    addCard = (name: string, customGrid?: Partial<GridItem>) => {
        const cardModule = new CardModule({ id: generateId(), name, type: modules.CARD, user: this.user, onDelete: () => { } }, {
            onCardCreate: () => this.addCard(name),
            onCardDelete: () => { }
        })
        this.addModule(cardModule, customGrid);
    }

    getDataContextSubscribeArgs(dataContext: TData): any[] {
        return [dataContext?.modules?.allModules]
    }

    getCreationDialog(): JSX.Element {
        return null;
    }
    customDelete(dataContext: TData): void {

    }
    renderTopMenu(dataContext: TData): JSX.Element {
        return null;
    }



    render(): JSX.Element {
        return <ReactGrid
            id={this.id}
            layout={this.layout}
            modules={this.modules}
            onDragStart={this.onDragStart}
            onDragStop={this.onDragStop}
            onDragOver={this.onDragOver}
            onResizeStop={this.onResizeStop}
            testDraggable={this.testDraggable}
        />
    }

    toFirebaseObject() {
        return {
            id: this.id,
            name: this.name,
            type: this.type,
            layout: this.layout.getILayout()
        }
    }

    // updateModuleFirebase(): void {
    //     const firebaseData: IBaseModule & {layout: ILayout} = {
    //         id: this.id, user: this.user, type: this.type, name: this.name,
    //         layout: this.layout.getILayout()
    //     }
    //     writeData(getModulesPath(this.user, this.id), firebaseData);
    // }

    updateLayoutFirebase() {
        writeData(`${getModulesPath(this.user, this.id)}/layout`, this.layout.getILayout());
    }

}