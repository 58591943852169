import * as React from 'react'
import { Button } from '../button/Button';
import { Activity } from '../../data/activity/Activity';
import { ActivityStatus } from '../../utils/constants';
import { Column } from '../layout/column/Column';
import { MarginBottom } from '../layout/margin/MarginBottom';
import { DateTimepicker } from '../pickers/datetime_picker/DateTimepicker';
import { ScheduledActivity } from '../activity_generator/ScheduledActivity';
import { generateId } from '../../utils/Utils';
import { DurationPicker } from '../pickers/duration_picker/DurationPicker';
import Modal from '../modal/Modal';

const ActivityViewer = ({ activity, sendToTodoList, onUpdateActivity, onDeleteActivity, onCreateScheduledActivity }: { activity: Activity, sendToTodoList, onUpdateActivity, onDeleteActivity, onCreateScheduledActivity }) => {
    const [plannedStartTime, setPlannedStartTime] = React.useState<Date>();
    const [showModal, setShowModal] = React.useState("");

    return (
        <Column alignLeft>
            <Button dataTestid="send-to-todo-button" label={'Köa upp aktiviteten'} onClick={() => {
                sendToTodoList(activity);
                // this.closeModal();
            }} />
            <MarginBottom />
            <Button dataTestid="manual-scheduling-button" label={'Planerad starttid för aktiviteten'} onClick={() => setShowModal("starttime")} />
            <MarginBottom />
            <Button dataTestid="delete-activity" label={'Ta bort aktivitet'} onClick={async () => {
                onDeleteActivity(activity);
                // this.rowData = this.filter(row => row.activity.id !== activity.id);
                // this.onDeleteActivity(activity);
            }} />
            <MarginBottom />
            <Button dataTestid="scheduling-interval" label={'Sätt intervall'} onClick={() => {
                setShowModal("interval");
            }} />
            <Modal showModal={!!showModal} setShowModal={setShowModal}>
                {showModal === "starttime" && <>
                    {
                        activity && <DateTimepicker
                            defaultTime={new Date()}
                            separateTimeAndDate
                            onChange={setPlannedStartTime}
                        />
                    }
                    <Button dataTestid="confirm-button" label={'Bekräfta'} onClick={() => {
                        const newScheduledActivity: ScheduledActivity = new ScheduledActivity({ id: generateId(), content: activity.name, status: ActivityStatus.NOT_STARTED, activityId: activity.id, startTime: plannedStartTime.getTime(), interval: activity.interval });
                        onCreateScheduledActivity(newScheduledActivity);
                        // this.closeModal()
                    }} />
                </>}
                {showModal === "interval" && <>
                    <DurationPicker
                        defaultValue={activity.interval || 0}
                        onChange={(millis: number) => {
                            activity.interval = millis;
                        }}
                    />
                    <Button dataTestid="confirm-button" label={'Bekräfta'} onClick={() => {
                        onUpdateActivity(activity);
                    }} />
                </>}
            </Modal>
        </Column>
    )
}

export { ActivityViewer }