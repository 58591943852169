import { ScheduledActivity } from "../components/activity_generator/ScheduledActivity";
import { BaseModule } from "../module/modules/BaseModule";
import { ReactGridModule } from "../module/modules/ReactGridModule/ReactGridModule";
import { TodoBoardModule } from "../module/modules/TodoBoardModule/TodoBoardModule";
import { convertTimestampToStringDate } from "../utils/TimeUtils";
import { TData } from "./interface";

export const scheduleActivity = async (dataContext: TData, scheduledActivity: ScheduledActivity, user) => {
    dataContext.scheduledActivities[scheduledActivity.id] = scheduledActivity;
    dataContext.scheduledActivities.refreshScheduledActivities();

    const calendarData = await dataContext.calendarData.getCalendarDate(convertTimestampToStringDate(scheduledActivity.startTime), true, false);
    calendarData.addScheduledActivity(scheduledActivity);
    dataContext.calendarData.setCalendarDate(calendarData, true);

    calendarData.updateFirebase(user);
    scheduledActivity.updateFirebase(user);
}

export const getRootLayout = async (dataContext: TData, user: string) => await dataContext.modules.getModule("LayoutStartPoint", dataContext, user, true) as ReactGridModule;
export const getTodoModule = async (dataContext: TData, user: string): Promise<TodoBoardModule> => await dataContext.modules.getModule("todoBoardModule", dataContext, user, true) as TodoBoardModule;

export const addModuleToRootLayout = async (dataContext: TData, user: string, module: BaseModule, updateFirebase?: boolean) => {
    const rootLayout = await getRootLayout(dataContext, user);
    rootLayout.addModule(module);
    if(updateFirebase){
        rootLayout.updateFirebase();
        module.updateFirebase();
    }
}

export const removeModuleFromRootLayout = async (dataContext: TData, user: string, module: BaseModule, updateFirebase?: boolean) => {
    const rootLayout = await getRootLayout(dataContext, user);
    rootLayout.removeModule(module.id);
    if(updateFirebase){
        rootLayout.updateFirebase();
    }
}

export const clearObjectAndNestedObjectAndArraysOfUndefines = (obj: any) => {
        if (Array.isArray(obj)) {
          return obj.map(clearObjectAndNestedObjectAndArraysOfUndefines).filter(value => value !== undefined);
        } else if (obj !== null && typeof obj === 'object') {
          return Object.keys(obj).reduce((acc, key) => {
            const cleanedValue = clearObjectAndNestedObjectAndArraysOfUndefines(obj[key]);
            if (cleanedValue !== undefined) {
              acc[key] = cleanedValue;
            }
            return acc;
          }, {});
        }
        return obj;
}